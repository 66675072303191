
export default {
 name: "Youtube",
 props: {
    id: {
      default: '',
      type: String,
    },
 },
 data() {
    return {
isVisible:false,
schemaData: null
    }
  },
  head() {
    if(this.structuredData){
      return {
        script: [{ type: 'application/ld+json', json: this.structuredData }]
      }
    }

  },
  computed: {
    structuredData() {
      if(this.schemaData) {
        return {
        "@context":"https://schema.org",
        "@type":"VideoObject",
        "name":this.schemaData.snippet.title,
        "description":this.schemaData.snippet.description,
        "thumbnailUrl":this.schemaData.snippet.thumbnails.default.url,
        "uploadDate":this.schemaData.snippet.publishedAt,
        "duration":this.schemaData.contentDetails.duration,
        "contentUrl":'https://www.youtube.com/watch?v=' + this.$props.id,
      }
      } else {
        return null
      }

    }
  },
  mounted() {
    this.fetchSchemaData()
  },
  methods:{
    visibilityChanged(isVisible, entry) {
      this.isVisible = isVisible
      console.log(isVisible, entry)
    },
    //  getJSON(url, callback){
    //   const func = this.setSchemaData
    //   const xhr = new XMLHttpRequest();
    //   xhr.open('GET', url, true);
    //   xhr.responseType = 'json';
    //   xhr.onload   = function() {
    //     const status = xhr.status;
    //     if (status === 200) {
    //       callback(null, xhr.response, func);
    //     } else {
    //       callback(status, xhr.response, func);
    //     }
    //   };
    //   xhr.send();
    // },
    // setSchemaData(data) {
    //   console.log('setting data', data)
    //   this.schemaData = data
    // },
    //  fetchSchemaData() {
    //    this.getJSON('https://noembed.com/embed?url=http://www.youtube.com/watch?v='+this.$props.id,
    //     function(err, data, func) {
    //     if (err !== null) {
    //       console.log(err)
    //     } else {

    //       func(data)

    //     }
    //   })
    // },
    async fetchSchemaData() {
      const result = await fetch(`https://www.googleapis.com/youtube/v3/videos?key=${process.env.YOUTUBE_DATA_API_KEY}&part=snippet,contentDetails&id=${this.$props.id}`)
      const data = await result.json()
      this.schemaData = data.items[0]
    }


  }
}
