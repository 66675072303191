
export default {
  props:{
    region:{
      type:String,
      default:'uk',
    },
  },
  data() {
    return {

      isVisible:false
    }
  },
  methods: {
    visibilityChanged(isVisible, entry) {
      this.isVisible = isVisible
      // console.log(isVisible, entry)
    }
  }
}
